<template>
	<div class="homepage">
		<div v-if="banner?.type === 'Main banner'">
			<WelcomeBanner
				:title="banner.title || $t('pages.home.default_welcome', { mktplc: platformName })"
				:img="`${imgixEcmWpcUrl}/${banner.image_id}?w=1536&fm=webp&auto=compress`"
				:font="plfm.secondaryFont"
				alignment="center"
				class="homepage__banner tg-title-font tg-title-color"
			>
				<Button
					v-if="banner.show_link"
					:color="plfm.btns.bg"
					class="tg-btn"
					variant="flat"
					right-icon="md-chevron_right"
					href="/textiles"
					>{{ banner.link_text }}
				</Button>
			</WelcomeBanner>

			<div class="text-center my-[60px] flex justify-center">
				<p class="w-[900px]">
					{{ banner.text }}
				</p>
			</div>
		</div>
		<div
			v-for="(c, index) in customSections"
			:key="index"
		>
			<component
				:is="CmsComponents[c.component_name]"
				v-if="Object.keys(CmsComponents).includes(c.component_name)"
				v-bind="JSON.parse(c.component_data)"
			>
			</component>
		</div>
		<div
			class="mx-auto my-[40px]"
			:class="plfm.isEditorial ? 'max-w-[1240px]' : 'max-w-[1360px]'"
		>
			<MerchantsRecentList class="py-8 mobile:px-[15px] mx-auto tablet:px-[4%]" />
		</div>
	</div>
</template>

<script lang="ts" setup>
import FeaturedProducts from "@/components/Cms/FeaturedProducts.vue";
import MerchantsRecentList from "@/components/MerchantsRecentList.vue";

const CmsComponents = ref<{ [key: string]: any }>({
	FeaturedProducts,
});

const { platform, platformName, plfm, imgixEcmWpcUrl } = storeToRefs(usePlatformStore());

const pages = computed(() => platform.value?.platform_settings[0]?.pages || []);

const banner = computed(() => {
	const pageDetails = pages.value.filter(page => page?.page_name === "Homepage");
	const obj = pageDetails[0]?.components.find(comp => comp.component_name === "WelcomeBanner");
	return obj ? JSON.parse(obj.component_data) : null;
});

const customSections = computed(() => {
	const pageDetails = pages.value.filter(page => page?.page_name === "Homepage");
	return pageDetails[0]?.components
		.filter(comp => comp.component_name !== "WelcomeBanner")
		.sort((a, b) => a.sequence_order - b.sequence_order);
});

useHead({
	title: `${platformName.value}`,
});

// function getBannerUrl(componentData: string) {
// 	const data = JSON.parse(componentData);
// 	const url = data.link_value as string;
// 	return url.includes("https://") ? url : "https://" + url;
// }
</script>
<style lang="scss" scoped>
.homepage {
	&__banner {
		@apply min-h-[500px] bg-transparent text-white;
		:deep(.subtitle) {
			@apply mt-[40px];
		}

		:deep(h2) {
			@apply max-w-[630px] mx-auto;
		}
	}
}
</style>
