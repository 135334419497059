<template>
	<div class="">
		<section
			:class="`flex mb-4  ${
				plfm.isEditorial
					? 'flex-wrap justify-center gap-1'
					: 'mobile:flex-wrap mobile:justify-center md:flex-nowrap  md:items-end md:justify-between'
			} `"
		>
			<h2
				class="tg-subtitle-color tg-title-font text-center mobile:text-[24px] mobile:leading-[40px]"
				:class="
					plfm.isEditorial
						? 'text-[40px] leading-[48px] basis-full'
						: 'text-[32px] leading-[40px] basis-full md:basis-auto'
				"
			>
				{{ $t("components.merchants_recent_list.title") }}
			</h2>
			<NuxtLink
				to="/merchants"
				class="tg-link-color flex items-center gap-1"
			>
				<span>{{ $t("components.merchants_recent_list.view_all") }}</span>

				<Icon
					v-if="!plfm.isEditorial"
					name="md-chevron_right"
				/>
			</NuxtLink>
		</section>
		<section>
			<div
				v-if="fetchingMerchants"
				class="flex flex-wrap gap-10 sm:gap-0 sm:-mx-5"
			>
				<v-skeleton-loader
					v-for="(v, i) in Array(skeletonsShown)"
					:key="i"
					type="card"
					:class="gridLayout + ''"
				></v-skeleton-loader>
			</div>
			<div
				v-else
				class="flex flex-wrap gap-10 sm:gap-0 sm:-mx-5"
			>
				<div
					v-for="merchant in list"
					:key="merchant.id"
					:class="gridLayout"
				>
					<MerchantCard
						:merchant="merchant"
						:centered="plfm.isEditorial"
					/>
				</div>
			</div>
		</section>
	</div>
</template>

<script lang="ts" setup>
import MerchantCard from "@/components/MerchantCard.vue";
import type { MerchantCardInfo } from "@/types/merchant";

const { platformId, plfm } = storeToRefs(usePlatformStore());
const { getRecentlyListedMerchants } = useMerchantsStore();

const fetchingMerchants = ref(true);
const list = ref<MerchantCardInfo[]>([]);
const gridLayout = computed(() => {
	const cols = plfm.value.isEditorial ? "lg:basis-4/12" : "lg:basis-4/12 xl:basis-3/12 ";
	return `basis-full sm:basis-1/2 sm:p-5 h-full ${cols}`;
});

const skeletonsShown = computed(() => (plfm.value.isEditorial ? 6 : 4));

onMounted(async () => {
	try {
		fetchingMerchants.value = true;
		list.value = await getRecentlyListedMerchants(platformId.value, skeletonsShown.value);
	} catch (error) {}
	fetchingMerchants.value = false;
});
</script>
