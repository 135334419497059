<template>
	<div class="w-full">
		<div
			v-if="products?.length"
			class="mx-auto my-[40px]"
			:class="plfm.isEditorial ? 'max-w-[1240px]' : 'max-w-[1360px]'"
		>
			<div class="mb-[48px] mobile:mb-[25px] mobile:px-[15px] mx-auto tablet:px-[4%]">
				<div
					class="flex mobile:mb-[10px]"
					:class="plfm.isEditorial ? 'flex-col justify-center mb-[40px]' : 'items-center justify-between mb-[24px]'"
				>
					<h2
						class="tg-title-font tg-subtitle-color text-center mobile:text-[24px] mobile:leading-[40px]"
						:class="plfm.isEditorial ? 'text-[40px] leading-[48px] mb-[8px]' : 'text-[32px] leading-[40px]'"
					>
						{{ title }}
					</h2>
					<NuxtLink
						v-if="ctaText && ctaLink"
						:to="ctaLink"
						class="text-[16px] leading-[24px] tg-link-color"
						:class="plfm.isEditorial ? 'self-center' : 'flex self-end'"
					>
						<span>{{ ctaText }}</span>
						<Icon
							name="md-chevron_right"
							:style="
								plfm.isEditorial
									? {
											display: 'none',
									  }
									: {}
							"
						/>
					</NuxtLink>
				</div>
				<div class="mx-auto">
					<div
						class="grid"
						:class="
							plfm.isEditorial
								? 'grid-cols-[repeat(auto-fill,minmax(380px,1fr))] gap-[50px]'
								: 'grid-cols-[repeat(auto-fill,minmax(256px,1fr))] gap-[20px]'
						"
					>
						<ProductCardComponent
							v-for="product in products"
							:key="product.id"
							class="place-self-center"
							:product="product"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import type { Response } from "@tengiva/services-api-layer/types/response";
import ProductCardComponent from "@/components/Product/ProductCardComponent.vue";
import type { Order } from "@/types/filters";
import type { CatalogTextileItem } from "@/types/products";

const { t } = useI18n();

const props = defineProps<{
	type: string;
	automatic_filter?: string;
	collection_id?: string;
}>();

const { flattenCollectionsTextiles } = useCollectionsStore();

const { platformId, plfm } = storeToRefs(usePlatformStore());

const { listProductsForMktplcMarketplace } = usePMApi();
const { $sentry } = useNuxtApp();

const products = ref<CatalogTextileItem[]>([]);

const { viewCollectionMarketplace } = usePMApi();

const title = ref<string>("");
const ctaText = ref<string>("");
const ctaLink = ref<any>();

onMounted(async () => {
	if (props.type === "collection" && props.collection_id) {
		const response = await fetchCollectionListingProducts(props.collection_id);

		products.value = flattenCollectionsTextiles(response);

		title.value = response?.name || "";
		ctaText.value = t("components.featured_products.collection.cta_text", { name: title.value });
		ctaLink.value = { name: "collections-slug", params: { slug: response?.slug || "" } };
	} else if (props.type === "automated" && props.automatic_filter === "created_desc") {
		const response = await fetchAutomaticListingProducts({
			"platform_catalog_products.created": "DESC",
		});

		products.value = response?.textile_items || [];

		title.value = t("components.featured_products.created_desc.title");
		ctaText.value = t("components.featured_products.created_desc.cta_text");
		ctaLink.value = { name: "textiles", query: { order: "date_desc" } };
	}
});

const fetchCollectionListingProducts = async (collectionId: string) => {
	try {
		const { response, error } = (await viewCollectionMarketplace(collectionId, {
			limit: plfm.value.isEditorial ? 6 : 5,
		})) as Response<{ textile_items: CatalogTextileItem[] }>;

		if (error) throw error;

		if (response?.data) return response?.data;
	} catch (error) {
		$sentry.captureException(`Cannot fetch collection ${collectionId} listing for featured products, ${error}`);
	}
};

const fetchAutomaticListingProducts = async (filters: Order) => {
	try {
		const { response, error } = (await listProductsForMktplcMarketplace(platformId.value, {
			filters,
			limit: plfm.value.isEditorial ? 6 : 5,
		})) as Response<{ textile_items: CatalogTextileItem[] }>;

		if (error) throw error;

		if (response?.data) return response?.data;
	} catch (error) {
		$sentry.captureException(`Cannot fetch automatic listing for featured textile_items, ${error}`);
	}
};
</script>

<style scoped></style>
